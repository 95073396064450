import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`💾 Deploying Polis`}</h1>
    <p>{`As of this writing — February 22nd, 2021 — `}<a parentName="p" {...{
        "href": "/Polis",
        "title": "Polis"
      }}>{`Polis`}</a>{` is close to having a Docker-based deployment solution. This has been thanks to the persistent efforts of a very dedicated team of `}<a parentName="p" {...{
        "href": "/open-source",
        "title": "open source"
      }}>{`open source`}</a>{` contributors, to whom we are deeply thankful.`}</p>
    <p>{`At the moment, `}<a parentName="p" {...{
        "href": "https://github.com/compdemocracy/polis"
      }}>{`installation docs`}</a>{` are somewhat focused on setting up a development environment with the `}<inlineCode parentName="p">{`docker-compose`}</inlineCode>{` tooling. Things are moving quickly though, so check back in soon for updates on this front.`}</p>
    <p>{`Old reference notes from `}<a parentName="p" {...{
        "href": "/PDIS",
        "title": "PDIS"
      }}>{`PDIS`}</a>{` deployment process can be found here: `}<a parentName="p" {...{
        "href": "https://urakagi.gitbooks.io/local-polis/content/chapter1.html"
      }}>{`https://urakagi.gitbooks.io/local-polis/content/chapter1.html`}</a></p>
    <h3>{`Who do I need?`}</h3>
    <p>{`An ops person familiar with Docker should be able to take the infrastructure currently available in the Polis repository and use it to create a dedicated deployment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      